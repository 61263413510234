.Graph__container {
    display: flex;
    flex-direction: row;
    margin-bottom: 7vh;
}

.Bar__funnelContainer{
    /* background-color: #232323; */
    background: var(--lvl1);
    border-radius: 32px;
    /* left: auto; */
    width: 100vw;
    padding: 5vh 5vw;
    margin-right: 5vw;
}



.Bar__barContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 12px;
    /* background-color: #383838; */
    background: var(--lvl2);
    margin-bottom: 4px;
}

.Bar__stepTitle{
    min-width: 10vw;
    max-width: 10vw;
    margin: 20px 20px; 
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    word-wrap: break-word;
}

.Bar__bar__foreground{
    background: linear-gradient(#8ADEFF, #5DD0FF);
    height: 32px;
    border-radius: 4px;
    margin-right:4px;
}

.Bar__bar__background{
    margin-right: 60px;
    background: transparent;
}

.Bar__sourceInfo{
    padding-left: 48px;
    margin-left: 10vw;
}



.FT__buttContaner{
    display: flex;
    flex-direction: row;
    align-items:bottom;
    margin-left: 20vw;
    padding-left: 30px;
}

.FT__button{
    margin-right: 8px;
    /* background-color: #121212; */
    background: var(--lvl-1);
    font-size: 14px;
    color: #fff;
    word-wrap: break-word;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding: 8px 24px;
    margin-top: 32px;
    border: 3px solid var(--yellow2);
    border-bottom: none;
}

.FT__button:hover{
    /* background-color: #232323; */
    background: var(--lvl1);
    border: 3px solid #fff;
    border-bottom: none;
}




.FT__buttonSelected{
    margin-right: 8px;
    /* background-color: #232323; */
    background: var(--lvl1);
    padding: 24px 32px;  
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 32px;
}


.FT__newFunnel{
    margin-top: 32px;
    margin-left: 16px;
}







.Tag__container{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-left: 5vw;
    margin-bottom: 24px;
    width: 22vw;
}


.Tag__button{
    margin-bottom: 8px;
}

.Tag__manageSources{
    margin: 16px;
}

/* .Tag__button[aria-pressed="false"] {
    background-color: blueviolet;
 } */



.Tag__buttonSelected{
    /* background-color: #232323; */
    background: var(--lvl1);
    padding: 24px;
    font-size: 24px;
    margin-bottom: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}


.Bar__editButton{
    margin-top: 16px;
    width: 15vw;
}



.Bar__convAndDateContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}


.Bar__date{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 24px;
}

.Bar__dateSpan{
    color: #121212;
    font-size: 24px;
    text-decoration: underline;
    margin-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.Bar__dateIcon{
    margin-left: 16px;
    margin-right: 4px;
}

.Bar__convAndDateContainer{
    color:#C9C9C9;
}

.Bar__dateContainer{
    display: flex;
    flex-direction: row;
    align-items: center;

}
.Bar__date{
    color: #C9C9C9; 
    font-size: 12px;
    margin-left: 0px;
}

.Bar__deleteContainer{
    position: relative;
}

.Bar__deleteButton{
    background-color: #EF9A9A;
    border-radius: 24px;
    position: absolute;
    right: 0;
    bottom: 0px;
}

.Bar__deleteButton:hover{
    background-color: #fff;
}

.Bar__deleteText{
    color: #121212;
    padding: 8px 12px;
    font-size: 12px;
}
