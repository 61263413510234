

.navContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--lvl1);
    margin-bottom: 5vh;
    padding-top: 8px;
    padding-bottom: 8px;

}


.logo{
    width: 180px;
    margin-left: 2vw;
    margin-top: 4px;
    padding: 16px 24px;
    border-radius: 8px;
}

.logo:hover{
    background: var(--lvl2);
}


select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select::-ms-expand {
    display: none;
}


.website{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    padding: 8px 16px;
    padding-right: 32px;
    margin: 12px 2vw;
    background: url(./arrowIcon.svg) no-repeat right var(--lvl2);

    color: #C9C9C9;
    font-size: 16px;

}

.website:hover{
    background: url(./arrowIcon.svg) no-repeat right var(--lvl4);
}


.selectedWebsite{
    color: #C9C9C9;
    font-size: 16px;
    margin-right: 24px;
}




