/* Styling for Funnel.tsx */

.formContainer{
  /* background-color: #232323; */
  background: var(--lvl1);
  border-radius: 32px;
  max-width: 100vw;
  padding: 5vh 5vw;
  margin-right: 5vw;
  margin-left: 5vw;
  margin-top: 5vh;
  position: relative;
  border: 8px solid var(--yellow2);
}




/* FUNNEL NAME */

.funnelName{
  color: #C9C9C9;
  font-size: 18px;
  margin-bottom: 3vh;
  margin-top: 4vh;
}

.funnelNameInput{
  display: flex;
  flex-direction: column;
  background-color: transparent;
  /* border: 1px solid #383838; */
  border: 1px solid var(--button1);
  border-radius: 12px;
  font-size: 36px;
  width: 50%;
  padding: 8px 24px;
  margin-top: 7vh;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}




/* STEPS */

.stepAndUrl{
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  margin-bottom: 4px;
  color: #828282;
  font-size: 14px;
  margin-left: 8px;
}

.url{
  margin-left: 36px;
}

.stepContCont{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stepContainer{
  /* background-color: #383838; */
  background: var(--lvl2);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 8px;
  width: 100%;
}


input{
  outline: none;
}

.stepInput{
  /* background-color: #383838; */
  background: transparent;
  /* border: 1px solid #585858; */
  border: 1px solid var(--button1);
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  outline: none;
  padding: 8px 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}

.stepName{
  margin-left: 24px;
  margin-right: 16px;
  min-width: 20%;
  font-size: 10px;
}

.stepUrl{
  width: 100%;
  margin-right: 24px;
  margin-left: 24px;
}

.addStepButton{
  margin: 2vh 2vw;
  margin-bottom: 10vh; 
}




/* BUTTONS */


.removeButton{
  margin-left: 8px;
}



.submitPos{
  position: absolute;
  right: 10vw;
  bottom: 5vh;
}


