.container{
    /* background-color: #232323; */
    background: var(--lvl1);
    border-radius: 32px;
    max-width: 100vw;
    padding: 5vh 5vw;
    margin: 7vh 5vw;
    position: relative;
    border: 8px solid var(--blue2);
}

.titleSources{
    color: #C9C9C9;
    font-size: 24px;
    margin-bottom: 2vh;
    margin-top: 2vh;

}

/* .sourcesContainer{
    
} */

.sourceContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputContainer{
    /* background-color: #383838; */
    background: var(--lvl2);
    border-radius: 12px;
    width: 100%;

    padding: 1vh 2vw;
    margin-bottom: 8px;
    margin-top: 8px;

}

.flexDemUp{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inpt{
    background: transparent;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Montserrat', sans-serif;
    /* border: 1px solid #585858; */
    border: 1px solid var(--button1);
    min-height: 40px;
}



.nameContainer{
    width: 10vw;
    margin-right: 48px;
    margin-top: 0px;
}
.name{
    margin-right: 16px;
    width: 10vw;
    font-size: 24px;
    margin-top: 0px;
}
  

.urlInContainer{
    width: 80%;
    margin-right: 36px;
    margin-top: 0px;
}
.urlIn{
    width: 100%;
    font-size: 18px;
    margin-top: 0px;
}




.copyContainer{
    display: flex;
    flex-direction: row;
    align-items: center;


    height: 40px;
    /* background: #585858; */
    background: var(--lvl3);


}

.blueBox{

    min-width: 8px;
    height: 100%;
    background: var(--blue2);
}

.urlOut{
    background: transparent;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 8px;


    

}

.copyButton{
    width: 24px;
    height: 24px;
    margin-right: 8px;
}



.newSourceButton{
    margin: 2vh 2vw;
    margin-bottom: 10vh; 
}