
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


:root{
    --lvl1:rgb(0,0,0,0.7);
    --lvl2: rgb(255,255,255,0.1);
    --lvl3: rgb(255,255,255,0.1);
    --lvl4: rgb(255,255,255,0.2);
    --lvl5: rgb(0,0,0,0.4);
    --lvl-1: rgb(0,0,0,0.8);
    --button1: rgb(255,255,255,0.5);

    --yellow1: #ffdf72;
    --yellow2: #FFF59D;

    --blue1: #80CBC4;
    --blue2: #8adeff;
}

body{
    font-family: "IBM Plex Mono", monospace;
    color: #fff;
    background: url(./bakgrunn3-01.png);
    background-size: 100%;

    margin: 0; 
}

a{
    color: #fff;
    text-decoration: none;
}

.tabButton{
    /* background-color: #121212; */
    background: var(--lvl-1);
    border: 3px solid var(--blue2);
    border-right: none;
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    word-wrap: break-word;
}

.tabButton:hover{
    /* background-color: #232323; */
    background: var(--lvl1);
    border: 3px solid #fff;
    border-right: none;

}

button,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  text-transform: none;
  cursor: pointer;
  border: 0;
  padding: 0;
    outline: none;
}

input{
    color: inherit;
    font: inherit;
    margin: 0;
    text-transform: none;
    border: 0;
    padding: 0;
    outline: none;
    margin-top: 12px;
    margin-bottom: 12px;
}



.buttonDiv{
    display: flex; 
    flex-direction: row;
    align-items: center;
    /* background-color: #828282; */
    background: var(--button1);
    border-radius: 24px;
    
}

.buttonIcon{
    margin-left: 16px;
    margin-right: 8px;
}

.buttonText{
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 24px;
    font-size: 12px;
    color: #121212;
}

.buttonDiv:hover{
    background-color: var(--yellow2);
}

.exit{
    position: absolute;
    right: 5vw;
}

.transpBack{
    background-color: transparent;
}



.submit{
    background-color: var(--yellow2);
    color: #121212;
    font-size: 24px;
    padding: 12px 32px;
    border-radius: 40px;
}
  
.submit:hover{
    background-color: #fff;

}





.infoText{
    font-size: 12px;
    color: #828282;
    margin-bottom: 2px;
    margin-left: 8px;
}


h3{
    color: #828282;
    font-size: 24px;
    font-weight: 400;
    margin: 0;

}

h4{
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 16px;
}

h5{
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    color: #C9C9C9;
}





.zoom:hover{
    transform: scale(1.1);
}