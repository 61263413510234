

.addWebContainer{
    /* background-color: #232323; */
    background: var(--lvl1);
    border-radius: 32px;
    max-width: 100vw;
    padding: 5vh 5vw;
    margin-right: 5vw;
    margin-left: 5vw;
    margin-top: 5vh;
    position: relative;

}

.link{
    color: var(--blue2);
    
}

.link:hover{
    text-decoration: underline;
}

.confirmButton{
    color: #121212;
    font-size: 24px;
    padding: 8px 24px;
    margin-top: 24px;
    margin-bottom: 16px;
    

}


.webInputContainer{
    /* background-color: #383838; */
    background: var(--lvl2);
    border-radius: 12px;
    width: 50vw;
    padding: 4px 24px;
    margin-right: 32px;
    padding-right: 58px;
    margin-bottom: 8px;

}

.webInput{
    width: 100%;
    background: transparent;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    border: 1px solid var(--button1);
    min-height: 40px;
    margin-right: 24px;
}








/* .inputContainer{
    background-color: #383838;
    border-radius: 12px;
    width: 100%;

    padding: 4vh 2vw;
    margin-bottom: 8px;

}
.name{
    margin-right: 16px;
    width: 10vw;
    font-size: 24px;
    margin-top: 0px;
}
.inpt{
    background: transparent;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #585858;
    min-height: 40px;
} */