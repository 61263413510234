@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);

/* Ikke i bruk. Kan slettes */


.Analytics {
  /* text-align: center; */
}

.Analytics-logo {
  /* height: 40vmin;
  pointer-events: none; */
}

.Analytics-header {
  /* background-color: #efefef;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.Analytics-box {
  /* background-color: #4444ff;
  min-width: 20%;
  padding: 15%;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; */
}

.Analytics-link {
  /* color: #61dafb; */
}


:root{
    --lvl1:rgb(0,0,0,0.7);
    --lvl2: rgb(255,255,255,0.1);
    --lvl3: rgb(255,255,255,0.1);
    --lvl4: rgb(255,255,255,0.2);
    --lvl5: rgb(0,0,0,0.4);
    --lvl-1: rgb(0,0,0,0.8);
    --button1: rgb(255,255,255,0.5);

    --yellow1: #ffdf72;
    --yellow2: #FFF59D;

    --blue1: #80CBC4;
    --blue2: #8adeff;
}

body{
    font-family: "IBM Plex Mono", monospace;
    color: #fff;
    background: url(/static/media/bakgrunn3-01.bc8c1b7e.png);
    background-size: 100%;

    margin: 0; 
}

a{
    color: #fff;
    text-decoration: none;
}

.tabButton{
    /* background-color: #121212; */
    background: rgb(0,0,0,0.8);
    background: var(--lvl-1);
    border: 3px solid #8adeff;
    border: 3px solid var(--blue2);
    border-right: none;
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    word-wrap: break-word;
}

.tabButton:hover{
    /* background-color: #232323; */
    background: rgb(0,0,0,0.7);
    background: var(--lvl1);
    border: 3px solid #fff;
    border-right: none;

}

button,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  text-transform: none;
  cursor: pointer;
  border: 0;
  padding: 0;
    outline: none;
}

input{
    color: inherit;
    font: inherit;
    margin: 0;
    text-transform: none;
    border: 0;
    padding: 0;
    outline: none;
    margin-top: 12px;
    margin-bottom: 12px;
}



.buttonDiv{
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-direction: row; 
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    /* background-color: #828282; */
    background: rgb(255,255,255,0.5);
    background: var(--button1);
    border-radius: 24px;
    
}

.buttonIcon{
    margin-left: 16px;
    margin-right: 8px;
}

.buttonText{
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 24px;
    font-size: 12px;
    color: #121212;
}

.buttonDiv:hover{
    background-color: #FFF59D;
    background-color: var(--yellow2);
}

.exit{
    position: absolute;
    right: 5vw;
}

.transpBack{
    background-color: transparent;
}



.submit{
    background-color: #FFF59D;
    background-color: var(--yellow2);
    color: #121212;
    font-size: 24px;
    padding: 12px 32px;
    border-radius: 40px;
}
  
.submit:hover{
    background-color: #fff;

}





.infoText{
    font-size: 12px;
    color: #828282;
    margin-bottom: 2px;
    margin-left: 8px;
}


h3{
    color: #828282;
    font-size: 24px;
    font-weight: 400;
    margin: 0;

}

h4{
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 16px;
}

h5{
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;
    color: #C9C9C9;
}





.zoom:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.Graph__container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-bottom: 7vh;
}

.Bar__funnelContainer{
    /* background-color: #232323; */
    background: var(--lvl1);
    border-radius: 32px;
    /* left: auto; */
    width: 100vw;
    padding: 5vh 5vw;
    margin-right: 5vw;
}



.Bar__barContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 12px;
    /* background-color: #383838; */
    background: var(--lvl2);
    margin-bottom: 4px;
}

.Bar__stepTitle{
    min-width: 10vw;
    max-width: 10vw;
    margin: 20px 20px; 
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    word-wrap: break-word;
}

.Bar__bar__foreground{
    background: linear-gradient(#8ADEFF, #5DD0FF);
    height: 32px;
    border-radius: 4px;
    margin-right:4px;
}

.Bar__bar__background{
    margin-right: 60px;
    background: transparent;
}

.Bar__sourceInfo{
    padding-left: 48px;
    margin-left: 10vw;
}



.FT__buttContaner{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items:bottom;
            align-items:bottom;
    margin-left: 20vw;
    padding-left: 30px;
}

.FT__button{
    margin-right: 8px;
    /* background-color: #121212; */
    background: var(--lvl-1);
    font-size: 14px;
    color: #fff;
    word-wrap: break-word;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding: 8px 24px;
    margin-top: 32px;
    border: 3px solid var(--yellow2);
    border-bottom: none;
}

.FT__button:hover{
    /* background-color: #232323; */
    background: var(--lvl1);
    border: 3px solid #fff;
    border-bottom: none;
}




.FT__buttonSelected{
    margin-right: 8px;
    /* background-color: #232323; */
    background: var(--lvl1);
    padding: 24px 32px;  
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 32px;
}


.FT__newFunnel{
    margin-top: 32px;
    margin-left: 16px;
}







.Tag__container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 32px;
    margin-left: 5vw;
    margin-bottom: 24px;
    width: 22vw;
}


.Tag__button{
    margin-bottom: 8px;
}

.Tag__manageSources{
    margin: 16px;
}

/* .Tag__button[aria-pressed="false"] {
    background-color: blueviolet;
 } */



.Tag__buttonSelected{
    /* background-color: #232323; */
    background: var(--lvl1);
    padding: 24px;
    font-size: 24px;
    margin-bottom: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}


.Bar__editButton{
    margin-top: 16px;
    width: 15vw;
}



.Bar__convAndDateContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-bottom: 24px;
}


.Bar__date{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 24px;
}

.Bar__dateSpan{
    color: #121212;
    font-size: 24px;
    text-decoration: underline;
    margin-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.Bar__dateIcon{
    margin-left: 16px;
    margin-right: 4px;
}

.Bar__convAndDateContainer{
    color:#C9C9C9;
}

.Bar__dateContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;

}
.Bar__date{
    color: #C9C9C9; 
    font-size: 12px;
    margin-left: 0px;
}

.Bar__deleteContainer{
    position: relative;
}

.Bar__deleteButton{
    background-color: #EF9A9A;
    border-radius: 24px;
    position: absolute;
    right: 0;
    bottom: 0px;
}

.Bar__deleteButton:hover{
    background-color: #fff;
}

.Bar__deleteText{
    color: #121212;
    padding: 8px 12px;
    font-size: 12px;
}



.navContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: var(--lvl1);
    margin-bottom: 5vh;
    padding-top: 8px;
    padding-bottom: 8px;

}


.logo{
    width: 180px;
    margin-left: 2vw;
    margin-top: 4px;
    padding: 16px 24px;
    border-radius: 8px;
}

.logo:hover{
    background: var(--lvl2);
}


select {
    -webkit-appearance: none;
    appearance: none;
}

select::-ms-expand {
    display: none;
}


.website{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 8px;
    padding: 8px 16px;
    padding-right: 32px;
    margin: 12px 2vw;
    background: url(/static/media/arrowIcon.d949617f.svg) no-repeat right var(--lvl2);

    color: #C9C9C9;
    font-size: 16px;

}

.website:hover{
    background: url(/static/media/arrowIcon.d949617f.svg) no-repeat right var(--lvl4);
}


.selectedWebsite{
    color: #C9C9C9;
    font-size: 16px;
    margin-right: 24px;
}









.Button {
  background-color: lightyellow;
  padding: 10px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.Button__link {
  background-color: #4444ff;
  padding: 10px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
  margin-bottom: 10px;
  color: whitesmoke;
}

.Button__welcome {
  background-color: #9ac2c5;
}

.forsideLogo{
    width: 25vw;
    max-width: 1000px;
    margin: 0vh 10vw;
    padding: 4vh 40px;
    border-radius: 24px;
    background: var(--lvl5);


}

.infoContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: top;
            align-items: top;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    /* background-color: #232323; */
    background: var(--lvl1);
    margin: 2vh 10vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 5vw;
   
    border-radius: 24px;
}

.info{
    padding-right: 6vw;
    width: 33.3%;
}

.infoHeading{
    font-size: 32px;
    margin-bottom: 8px;
}

.infoBody{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #C9C9C9;
    line-height: 1.5;
    margin-bottom: 16px;
}

.learnMore{
    color: var(--blue2);
    font-size: 14px;
}


.learnMore:hover{
    text-decoration: underline;
}

.getStarted{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
/* Styling for Funnel.tsx */

.formContainer{
  /* background-color: #232323; */
  background: var(--lvl1);
  border-radius: 32px;
  max-width: 100vw;
  padding: 5vh 5vw;
  margin-right: 5vw;
  margin-left: 5vw;
  margin-top: 5vh;
  position: relative;
  border: 8px solid var(--yellow2);
}




/* FUNNEL NAME */

.funnelName{
  color: #C9C9C9;
  font-size: 18px;
  margin-bottom: 3vh;
  margin-top: 4vh;
}

.funnelNameInput{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: transparent;
  /* border: 1px solid #383838; */
  border: 1px solid var(--button1);
  border-radius: 12px;
  font-size: 36px;
  width: 50%;
  padding: 8px 24px;
  margin-top: 7vh;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}




/* STEPS */

.stepAndUrl{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: baseline;
          justify-content: baseline;
  margin-bottom: 4px;
  color: #828282;
  font-size: 14px;
  margin-left: 8px;
}

.url{
  margin-left: 36px;
}

.stepContCont{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.stepContainer{
  /* background-color: #383838; */
  background: var(--lvl2);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 12px;
  margin-bottom: 8px;
  width: 100%;
}


input{
  outline: none;
}

.stepInput{
  /* background-color: #383838; */
  background: transparent;
  /* border: 1px solid #585858; */
  border: 1px solid var(--button1);
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  outline: none;
  padding: 8px 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}

.stepName{
  margin-left: 24px;
  margin-right: 16px;
  min-width: 20%;
  font-size: 10px;
}

.stepUrl{
  width: 100%;
  margin-right: 24px;
  margin-left: 24px;
}

.addStepButton{
  margin: 2vh 2vw;
  margin-bottom: 10vh; 
}




/* BUTTONS */


.removeButton{
  margin-left: 8px;
}



.submitPos{
  position: absolute;
  right: 10vw;
  bottom: 5vh;
}



.container{
    /* background-color: #232323; */
    background: var(--lvl1);
    border-radius: 32px;
    max-width: 100vw;
    padding: 5vh 5vw;
    margin: 7vh 5vw;
    position: relative;
    border: 8px solid var(--blue2);
}

.titleSources{
    color: #C9C9C9;
    font-size: 24px;
    margin-bottom: 2vh;
    margin-top: 2vh;

}

/* .sourcesContainer{
    
} */

.sourceContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.inputContainer{
    /* background-color: #383838; */
    background: var(--lvl2);
    border-radius: 12px;
    width: 100%;

    padding: 1vh 2vw;
    margin-bottom: 8px;
    margin-top: 8px;

}

.flexDemUp{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.inpt{
    background: transparent;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Montserrat', sans-serif;
    /* border: 1px solid #585858; */
    border: 1px solid var(--button1);
    min-height: 40px;
}



.nameContainer{
    width: 10vw;
    margin-right: 48px;
    margin-top: 0px;
}
.name{
    margin-right: 16px;
    width: 10vw;
    font-size: 24px;
    margin-top: 0px;
}
  

.urlInContainer{
    width: 80%;
    margin-right: 36px;
    margin-top: 0px;
}
.urlIn{
    width: 100%;
    font-size: 18px;
    margin-top: 0px;
}




.copyContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;


    height: 40px;
    /* background: #585858; */
    background: var(--lvl3);


}

.blueBox{

    min-width: 8px;
    height: 100%;
    background: var(--blue2);
}

.urlOut{
    background: transparent;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 8px;


    

}

.copyButton{
    width: 24px;
    height: 24px;
    margin-right: 8px;
}



.newSourceButton{
    margin: 2vh 2vw;
    margin-bottom: 10vh; 
}


.addWebContainer{
    /* background-color: #232323; */
    background: var(--lvl1);
    border-radius: 32px;
    max-width: 100vw;
    padding: 5vh 5vw;
    margin-right: 5vw;
    margin-left: 5vw;
    margin-top: 5vh;
    position: relative;

}

.link{
    color: var(--blue2);
    
}

.link:hover{
    text-decoration: underline;
}

.confirmButton{
    color: #121212;
    font-size: 24px;
    padding: 8px 24px;
    margin-top: 24px;
    margin-bottom: 16px;
    

}


.webInputContainer{
    /* background-color: #383838; */
    background: var(--lvl2);
    border-radius: 12px;
    width: 50vw;
    padding: 4px 24px;
    margin-right: 32px;
    padding-right: 58px;
    margin-bottom: 8px;

}

.webInput{
    width: 100%;
    background: transparent;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    border: 1px solid var(--button1);
    min-height: 40px;
    margin-right: 24px;
}








/* .inputContainer{
    background-color: #383838;
    border-radius: 12px;
    width: 100%;

    padding: 4vh 2vw;
    margin-bottom: 8px;

}
.name{
    margin-right: 16px;
    width: 10vw;
    font-size: 24px;
    margin-top: 0px;
}
.inpt{
    background: transparent;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #585858;
    min-height: 40px;
} */
