.forsideLogo{
    width: 25vw;
    max-width: 1000px;
    margin: 0vh 10vw;
    padding: 4vh 40px;
    border-radius: 24px;
    background: var(--lvl5);


}

.infoContainer{
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
    /* background-color: #232323; */
    background: var(--lvl1);
    margin: 2vh 10vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 5vw;
   
    border-radius: 24px;
}

.info{
    padding-right: 6vw;
    width: 33.3%;
}

.infoHeading{
    font-size: 32px;
    margin-bottom: 8px;
}

.infoBody{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #C9C9C9;
    line-height: 1.5;
    margin-bottom: 16px;
}

.learnMore{
    color: var(--blue2);
    font-size: 14px;
}


.learnMore:hover{
    text-decoration: underline;
}

.getStarted{
    display: flex;
    justify-content: center;
}