



.Button {
  background-color: lightyellow;
  padding: 10px;
  text-align: center;
  display: flex;
  border-radius: 10px;
}

.Button__link {
  background-color: #4444ff;
  padding: 10px;
  text-align: center;
  display: flex;
  border-radius: 10px;
  margin-bottom: 10px;
  color: whitesmoke;
}

.Button__welcome {
  background-color: #9ac2c5;
}
